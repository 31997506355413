import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import withRoot from "../withRoot"

import SectionHeader from "./sectionHeader"

const styles = theme => ({
  root: {
    margin: "0 auto",
    maxWidth: 480,
    textAlign: "center",
  },
})

const About = ({ data, classes }) => {
  return (
    <div id="over">
      <SectionHeader sectionName="OVER" />
      <Typography className={classes.root} variant="body1" gutterBottom>
        Na de onvergetelijke editie van vorig jaar keert KNSRBeach terug op vrijdag 11 juli 2025! Het belooft een avond voor in de boeken te worden, zien we je daar?
      </Typography>
    </div>
  )
}
export default withRoot(withStyles(styles)(About))
